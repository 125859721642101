import * as types from '../constants/ActionTypes';
import {BASE_URL} from '../constants/EnvVariables';


export function getPrices() {

    return dispatch => {
        dispatch({
            type: types.GET_PRICES_STARTED
        });

        window.$.ajax({
            type: "GET",
            dataType: 'json',
            url: BASE_URL + "prices",
            // headers: {
            //     'Authorization': localStorage.getItem('auth_token')
            // },
            success: function(data, status, xhr) {
                if(data.length <= 0){
                    data = []
                }

                dispatch({
                    type: types.GET_PRICES_SUCCESS,
                    info: data,
                });
            },

            error: function (data) {
                dispatch({
                    type: types.GET_PRICES_ERROR
                });
            }
        });
    };
}

export default {getPrices}