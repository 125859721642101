// Типы экшинов для редьюсеров

export const GET_EVENTS_STARTED = 'GET_EVENTS_STARTED';
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS';
export const GET_EVENTS_ERROR = 'GET_EVENTS_ERROR';

export const GET_COMMANDS_STARTED = 'GET_COMMANDS_STARTED';
export const GET_COMMANDS_SUCCESS = 'GET_COMMANDS_SUCCESS';
export const GET_COMMANDS_ERROR = 'GET_COMMANDS_ERROR';

export const GET_GAMES_STARTED = 'GET_GAMES_STARTED';
export const GET_GAMES_SUCCESS = 'GET_GAMES_SUCCESS';
export const GET_GAMES_ERROR = 'GET_GAMES_ERROR';

export const GET_IMAGES_STARTED = 'GET_IMAGES_STARTED';
export const GET_IMAGES_SUCCESS = 'GET_IMAGES_SUCCESS';
export const GET_IMAGES_ERROR = 'GET_IMAGES_ERROR';

export const GET_STOCKS_STARTED = 'GET_STOCKS_STARTED';
export const GET_STOCKS_SUCCESS = 'GET_STOCKS_SUCCESS';
export const GET_STOCKS_ERROR = 'GET_STOCKS_ERROR';

export const GET_ZONES_STARTED = 'GET_ZONES_STARTED';
export const GET_ZONES_SUCCESS = 'GET_ZONES_SUCCESS';
export const GET_ZONES_ERROR = 'GET_ZONES_ERROR';

export const GET_SERVICES_STARTED = 'GET_SERVICES_STARTED';
export const GET_SERVICES_SUCCESS = 'GET_SERVICES_SUCCESS';
export const GET_SERVICES_ERROR = 'GET_SERVICES_ERROR';

export const GET_PRICES_STARTED = 'GET_PRICES_STARTED';
export const GET_PRICES_SUCCESS = 'GET_PRICES_SUCCESS';
export const GET_PRICES_ERROR = 'GET_PRICES_ERROR';

export const GET_SEOS_STARTED = 'GET_SEOS_STARTED';
export const GET_SEOS_SUCCESS = 'GET_SEOS_SUCCESS';
export const GET_SEOS_ERROR = 'GET_SEOS_ERROR';