import * as types from '../constants/ActionTypes';
import test_event_1 from '../../images/test_event_1.jpg';
import test_event_2 from '../../images/test_event_2.jpg';

var testImages = [
    {
        id: 1,
        alt: '',
        src: test_event_1,
    }, 
    {
        id: 2,
        alt: '',
        src: test_event_2,  
    }
];

export function getImages() {
    return {
        type: types.GET_IMAGES_SUCCESS,
        info: testImages,
    };
}

export default {getImages}