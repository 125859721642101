import React, {lazy, Suspense} from 'react';
import {Provider} from 'beautiful-react-redux';
import createReactClass from 'create-react-class';
import {PersistGate} from 'redux-persist/integration/react'
import configureStore from '../config/configureStore'
import history from "../config/history";
import {Router} from "react-router-dom";
import {getEvents} from '../actions/EventsActions';
import {getCommands} from '../actions/CommandsActions';
import {getGames} from '../actions/GamesActions';
import {getImages} from '../actions/ImagesActions';
import {getStocks} from '../actions/StocksActions';
import {getZones} from '../actions/ZonesActions';
import {getServices} from '../actions/ServicesActions';
import {getPrices} from '../actions/PricesActions';
import {getSeos} from '../actions/SeosActions';
import jquery from 'jquery';

const BasicRouter = lazy(() => import('./BasicRouter'));
window.$ = jquery;
const renderLoader = () => <p>Loading</p>;

const {storeRedux, persistor} = configureStore();

const App = createReactClass({

    getInitialState() {
        window.countRenderedRoutes = 0;
        return {
            location: '/'
        }
    },

    componentDidMount() {
        this.routerRef = React.createRef();
        this.providerRef = React.createRef();
        storeRedux.dispatch(getEvents());
        storeRedux.dispatch(getCommands());
        storeRedux.dispatch(getGames());
        storeRedux.dispatch(getImages());
        storeRedux.dispatch(getStocks());
        storeRedux.dispatch(getZones());
        storeRedux.dispatch(getServices());
        storeRedux.dispatch(getPrices());
        storeRedux.dispatch(getSeos());
    },

    componentDidUpdate() {

    },

    setCurrentLocation(path) {
        this.setState({
            location: path
        })
    },

    render() {

        return (
            <div className="App">
                <Provider store={storeRedux} ref={this.providerRef}>
                    <PersistGate loading={null} persistor={persistor}>
                        <Router history={history}>
                            <div>
                                <Suspense fallback={renderLoader()}>
                                    <BasicRouter ref={this.routerRef} store={storeRedux}
                                                 setMainLocation={this.setCurrentLocation}
                                                 currentLocation={this.state.location}/>
                                </Suspense>
                            </div>
                        </Router>
                    </PersistGate>
                </Provider>
            </div>
        );
    }

});

export default App;
