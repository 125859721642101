import * as types from '../constants/ActionTypes';

const initialState = [];


export function commands(state, action) {
    state = state || initialState;

    switch (action.type) {
        case types.GET_COMMANDS_STARTED:
            return state;

        case types.GET_COMMANDS_SUCCESS:
            return action.info;

        case types.GET_COMMANDS_ERROR:
            return state;


        default:
            return state;
    }
}
export default commands