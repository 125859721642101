import { combineReducers } from 'redux'

// Манифест для редьюсеров, все необходимые добавляе сюда
import {events} from './events';
import {commands} from './commands';
import {games} from './games';
import {imagesSlider} from './images';
import {stocks} from './stocks';
import {zones} from './zones';
import {services} from './services';
import {prices} from './prices';
import {seos} from './seos';

const reducer = combineReducers({events, commands, games, imagesSlider, stocks, zones, services, prices, seos});


const rootReducer = (state, action) => {
    return reducer(state, action);
};
export default rootReducer;
