import { createStore, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native

import reducer from '../reducers'

// Префикс для хранилища
const persistConfig = {
    key: 'arena_landing',
    storage
};


// регистрируем редьюсеры
const persistedReducer = persistReducer(persistConfig, reducer);

// создаем store по умолчанию
export default function configureStore() {
    let storeRedux = createStore(persistedReducer, applyMiddleware(thunk));
    let persistor = persistStore(storeRedux);
    return { storeRedux, persistor }
}