import * as types from '../constants/ActionTypes';
import {BASE_URL} from "../constants/EnvVariables";


// Получение адреса для текущего экрана
export function getStocks() {
    return dispatch => {
        dispatch({
            type: types.GET_STOCKS_STARTED
        });

        window.$.ajax({
            type: "GET",
            dataType: 'json',
            url: BASE_URL + "stocks",
            // headers: {
            //     'Authorization': localStorage.getItem('auth_token')
            // },
            success: function(data, status, xhr) {
                dispatch({
                    type: types.GET_STOCKS_SUCCESS,
                    info: data,
                });
            },

            error: function (data) {
                dispatch({
                    type: types.GET_STOCKS_ERROR
                });
            }
        });
    };
}

export default {getStocks}